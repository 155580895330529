import {createElement, cloneElement} from 'react';

export function wrapIfString(toTest, tag, className) {
  if (typeof toTest === 'string') {
    const props = className ? {className} : null;

    return createElement(tag, props, toTest);
  }
  if (className) {
    return cloneElement(toTest, {className});
  }
  return toTest;
}
