import React from 'react';
import createReactClass from 'create-react-class';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import Card from '../../layout/Card/Card.jsx';
import setTitle from 'helpers/setTitle';
import {injectIntl, intlShape, FormattedMessage} from 'react-intl';

import styles from './LogoutPage.module.scss';

const LogoutPage = createReactClass({
  displayName: 'LogoutPage',
  propTypes: {
    intl: intlShape.isRequired,
  },
  mixins: [PureRenderMixin],

  componentDidMount() {
    location.href = window.idmAddress;
    setTitle(this.props.intl.formatMessage, 'logout.pageHeader');
  },

  render() {
    const {formatMessage} = this.props.intl;

    return <div className={styles.logout}>
      <Card header={formatMessage({id: 'logout.title'})}>
        <p><FormattedMessage id='logout.message' /></p>
        <p><FormattedMessage id='logout.close' /></p>
      </Card>
    </div>;
  },
});

export default injectIntl(LogoutPage);
