import React from 'react';
import createReactClass from 'create-react-class';
import PureRenderMixin from 'react-addons-pure-render-mixin';

import Card from '../../layout/Card/Card.jsx';

import setTitle from 'helpers/setTitle';
import {FormattedMessage, injectIntl, intlShape} from 'react-intl';

import styles from './RedirectError.module.scss';

const RedirectError = createReactClass({
  displayName: 'RedirectError',
  propTypes: {
    intl: intlShape.isRequired,
  },
  mixins: [PureRenderMixin],


  componentDidMount() {
    setTitle(this.props.intl.formatMessage, 'error.pageHeader');
  },

  render() {
    const {formatMessage} = this.props.intl;

    return <div className={styles.errorPage}>
      <Card header={formatMessage({id: 'error.title'})}>
        <p><FormattedMessage id='error.error' /></p>
        <p style={{fontStyle: 'italic'}}><FormattedMessage id='error.goBack' /></p>
        <p><FormattedMessage id='error.support' /></p>
      </Card>
    </div>;
  },
});

export default injectIntl(RedirectError);
