import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';

import styles from './LocaleSwitcher.module.scss';

const LocaleSwitcher = createReactClass({
  displayName: 'LocaleSwitcher',
  // Mixins: [PureRenderMixin],
  contextTypes: {
    currentLocale: PropTypes.string.isRequired,
    languages: PropTypes.array.isRequired,
    setLocale: PropTypes.func.isRequired,
  },


  getInitialState() {
    return {
      open: false,
    };
  },

  componentDidMount() {
    document.body.addEventListener('click', this.clickHandler);
  },

  componentWillUnmount() {
    document.body.removeEventListener('click', this.clickHandler);
  },

  clickHandler(e) {
    const selector = '.' + styles.switcher;

    if (this.state.open && !e.target.closest(selector)) {
      this.setState({open: false});
    }
  },


  toggle() {
    this.setState({open: !this.state.open});
  },

  setLang(lang) {
    return () => {
      this.setState({open: false}); // Close first incase async operation takes a while
      this.context.setLocale(lang);
    };
  },

  render() {
    const {currentLocale: locale, languages} = this.context;
    const fullLocale = languages.find(x => x.locale === locale);
    const otherLocales = languages.filter(x => x.locale !== locale);

    return <div className={styles.switcher + ' navItem'}>
      <div onClick={this.toggle} className={styles.current}>
        {fullLocale ? fullLocale.labels[fullLocale.locale] : 'English'}
        {this.state.open ? <i className='material-icons'>expand_less</i> : <i className='material-icons'>expand_more</i>}
      </div>
      {this.state.open ? <div className={styles.select}>
        <ul>
          {otherLocales.map(o => <li key={o.locale} onClick={this.setLang(o.locale)}>
            {/* Show the other languages in the form: Current Language Name (Native Language Name) e.g. French (Francais) */}
            {o.labels[fullLocale.locale]} ({o.labels[o.locale]})
          </li>)}
        </ul>
      </div> : null}
    </div>;
  },
});

export default LocaleSwitcher;
