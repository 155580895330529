import React from 'react';
import createReactClass from 'create-react-class';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import Card from '../../layout/Card/Card.jsx';
import styles from './SavedPassword.module.scss';

import setTitle from 'helpers/setTitle';
import {FormattedMessage, injectIntl, intlShape} from 'react-intl';

const SavedPassword = createReactClass({
  displayName: 'SavedPassword',
  propTypes: {
    intl: intlShape.isRequired,
  },
  mixins: [PureRenderMixin],

  componentDidMount() {
    setTitle(this.props.intl.formatMessage, 'savedPassword.pageHeader');

    setTimeout(() => {
      window.location.href = window.idmAddress + '/login';
    }, 2000);
  },

  render() {
    const {formatMessage} = this.props.intl;

    return <div className={styles.emailSent}>
      <Card header={formatMessage({id: 'savedPassword.title'})} className={styles.Card}>
        <div className='row'>
          <div className='col-xs-12'>
            <p><FormattedMessage id='savedPassword.saved' />
            </p>
          </div>
        </div>
      </Card>
    </div>;
  },
});

export default injectIntl(SavedPassword);
