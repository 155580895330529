import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import {browserHistory} from 'react-router';
import PureRenderMixin from 'react-addons-pure-render-mixin';

import Card from '../../layout/Card/Card.jsx';
import PasswordInput from '../../forms/PasswordInput/PasswordInput.jsx';
import Loader from '../../misc/Loader/Loader.jsx';

import setTitle from 'helpers/setTitle';
import {evaluateComplexity, passwordScore} from '../../../helpers/passwords.jsx';
import {PromiseStoreMixin} from '../../../helpers/request.js';

import {changePasswordReset} from '../../../api/userApi.js';

import styles from './ChangePassword.module.scss';

import debounce from 'lodash.debounce';

import {injectIntl, intlShape, FormattedMessage} from 'react-intl';

const ChangePassword = createReactClass({
  displayName: 'ChangePassword',
  mixins: [PureRenderMixin, PromiseStoreMixin],
  propTypes: {
    params: PropTypes.object.isRequired,
    location: PropTypes.object,
    intl: intlShape.isRequired,
  },

  contextTypes: {
    locale: PropTypes.string.isRequired,
    setLocale: PropTypes.func.isRequired,
  },

  // Using state here since need as you type functionality without spamming redux actions
  getInitialState() {
    return {
      password: '',
      passwordConfirm: '',
      errorReason: '',
      location: {},
      spinner: false,
    };
  },

  componentWillMount() {
    this.submitChange = debounce(this._submitChange, 500, {leading: true, trailing: false});
  },

  componentDidMount() {
    setTitle(this.props.intl.formatMessage, 'changePassword.pageHeader');
    if (this.props.location.query && this.props.location.query.locale && this.props.location.query.locale !== this.context.locale) {
      this.context.setLocale(this.props.location.query.locale);
    }
  },

  _submitChange(e) {
    e.preventDefault();
    const {password, passwordConfirm} = this.state;

    if (evaluateComplexity(password, passwordConfirm).valid) {
      this.promises.add(changePasswordReset(this.props.params.hash, password))
      .promise()
      .then(passwordRes => {
        if (passwordRes.success) {
          if (this.props.location.query && this.props.location.query.redirect) {
            window.location.href = this.props.location.query.redirect;
            return true;
          }
          if (passwordRes.redirect) {
            window.location.href = passwordRes.redirect;
            return true;
          }
          browserHistory.push('/saved-password');
          return true;
        }
        return Promise.reject(passwordRes);
      })
      .catch(e => {
        console.error(JSON.stringify(e, null, 2));
        this.setState({errorReason: e.message, spinner: false});
      });

      this.setState({
        spinner: true,
      });
    }
  },

  dataChanged(key) {
    return (e) => {
      const state = Object.assign({}, this.state);

      state[key] = e.target.value;
      this.setState(state);
    };
  },

  render() {
    const {password, passwordConfirm} = this.state;
    const {formatMessage} = this.props.intl;
    const {message} = evaluateComplexity(password, passwordConfirm);
    const score = passwordScore(password);
    const scoreConfirm = passwordScore(passwordConfirm);

    return <div className={styles.changePassword}>
      <Card header={formatMessage({id: 'changePassword.title'})} style={{paddingBottom: 20, width: 400, margin: '13vh auto 50px'}}>
        <form onSubmit={this.submitChange}>
          <div className='row'>
            <div className='col-xs-12'>
              {message}
            </div>
          </div>
          <div className='row'>
            <div className='col-xs-12'>
              <div className='form-group'>
                <label><FormattedMessage id='changePassword.fields.new' />
                  <PasswordInput onChange={this.dataChanged('password')} value={password} score={score} autoFocus={!!this.props.params.hash} />
                </label>
                <label><FormattedMessage id='changePassword.fields.confirm' />
                  <PasswordInput onChange={this.dataChanged('passwordConfirm')} value={passwordConfirm} score={scoreConfirm} />
                </label>
              </div>
            </div>
            <div className='col-xs-12'>
              <button className='btn btn-primary' type='submit'><FormattedMessage id='changePassword.fields.submit' /></button>
            </div>
          </div>
          {this.state.errorReason !== '' ? <div className='row'>
            <div className='col-xs-12'>
              <p className={styles.reason}>{this.state.errorReason}</p>
            </div>
          </div> : null}
        </form>
        {this.state.spinner ? <Loader style={{display: 'block', marginLeft: 'auto', marginRight: 'auto', marginTop: 15, marginBottom: 0}} /> : null}
      </Card>
    </div>;
  },
});

export default injectIntl(ChangePassword);
