import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import {Link} from 'react-router';

import Card from '../../layout/Card/Card.jsx';
import Modal from '../../layout/Modal/Modal';
import CustomLink from '../../layout/Link/Link';

import styles from './LoginPage.module.scss';

import {FormattedMessage, FormattedHTMLMessage, intlShape, injectIntl} from 'react-intl';
import setTitle from 'helpers/setTitle';

import Icon from '../../Icon/Icon.jsx';

const LoginPage = createReactClass({
  displayName: 'LoginPage',
  propTypes: {
    params: PropTypes.object.isRequired,
    intl: intlShape.isRequired,
  },
  mixins: [PureRenderMixin],

  getInitialState() {
    return {
      message: '',
      success: false,
      disclaimerModalVisible: false,
      privacyModalVisible: false,
    };
  },

  componentDidMount() {
    setTitle(this.props.intl.formatMessage, 'login.pageHeader');

    const remembered = localStorage.getItem('sso-rememberme');

    if (remembered && this.refs.email && this.refs.rememberMe && this.refs.password) {
      this.refs.email.value = remembered;
      this.refs.rememberMe.checked = true;
      this.refs.password.focus();
    }
  },

  componentDidUpdate() {
    setTitle(this.props.intl.formatMessage,'login.pageHeader');
  },

  onSubmit() {
    if (this.refs.rememberMe && this.refs.rememberMe.checked && this.refs.email) {
      const email = this.refs.email.value;

      localStorage.setItem('sso-rememberme', email);
    } else {
      localStorage.removeItem('sso-rememberme');
    }
    const recaptchaResponse = document.querySelector('.g-recaptcha-response');

    if (recaptchaResponse) {
      this.refs.recaptcha.value = recaptchaResponse.value;
    }
  },

  hideModals() {
    this.setState({
      disclaimerModalVisible: false,
      privacyModalVisible: false,
    });
  },

  openLinkNewTab(url) {
    const win = window.open(url, '_blank');

    win.focus();
  },

  render() {
    const id = this.props.params.id;
    const errorMessage = window.errorMessage;
    const captchaRequired = window.captchaRequired;
    const {formatMessage} = this.props.intl;

    return <div>
      <div className={styles.login}>
        <Card header={formatMessage({id: 'login.title'})} className={styles.loginCard} headerStyle>
          {errorMessage ? <div style={{marginBottom: 20}}>
            <Icon name='error' verticalAlign='text-bottom' className='red' />
            <p className='b red inline-block'> {errorMessage}</p>
          </div> : null}
          <form method='POST' onSubmit={this.onSubmit}>
            <div className='form-group'>
              <label>
                <FormattedMessage id='login.fields.email' />
                <input type='text' className='form-control' name='email' id='email' ref='email' autoFocus />
              </label>
            </div>
            <div className='form-group'>
              <label>
                <FormattedMessage id='login.fields.password' />
                <input type='password' name='phrase' id='phrase' className='form-control' ref='password' />
              </label>
            </div>
            <div className='form-group'>
              <label><input type='checkbox' defaultChecked={false} ref='rememberMe' />&nbsp;<FormattedMessage id='login.fields.remember' /></label>
            </div>
            {captchaRequired ? <div>
              <div className='g-recaptcha' data-sitekey='6LdTMSATAAAAAL2lE5UTWZlClUE2tGtOLycJBAjW' style={{marginBottom: 30}}></div>
              <input type='hidden' name='recaptcha' ref='recaptcha' />
            </div> : null}
            <p><FormattedMessage id='login.pptou.header' values={{
              MyBioGuardLink: <CustomLink label={formatMessage({id: 'login.pptou.myBioguard'})} action={() => this.openLinkNewTab('http://www.mybioguard.com')} bold />,
              TOULINK: <CustomLink label={formatMessage({id: 'login.pptou.termsUse'})}
                  action={() => this.setState({disclaimerModalVisible: true})} bold />,
              PPLINK: <CustomLink label={formatMessage({id: 'login.pptou.privacyPolicy'})}
                  action={() => this.setState({privacyModalVisible: true})} bold />,
            }} /></p>
            <input type='hidden' value={id} name='id' />
            <button type='submit' className='btn btn-primary' style={{marginRight: 20}}><FormattedMessage id='login.submit' /></button>
            <Link to='/reset-password'><FormattedMessage id='login.reset' /></Link>
          </form>
        </Card>
      </div>
      <div>
        {/*Disclaimer Modal*/}
        <Modal visible={this.state.disclaimerModalVisible}
          close={() => this.setState({disclaimerModalVisible: false})}
          id='app-disclaimer-modal' size='lg' calloutFooter={false}
          header={<h3><FormattedMessage id='footer.terms' /></h3>}
          footer={<button className='btn btn-primary' onClick={this.hideModals}><FormattedMessage id='general.close' /></button>}>
          <ul className="pptou-section">
            <li><FormattedHTMLMessage id='footer.terms.useUS' /></li>
            <li><FormattedHTMLMessage id='footer.terms.useCA' /></li>
            <li><FormattedHTMLMessage id='footer.terms.useCAFR' /></li>
          </ul>
        </Modal>
        {/*Privacy Modal*/}
        <Modal visible={this.state.privacyModalVisible}
          close={() => this.setState({privacyModalVisible: false})}
          id='app-privacy-modal' size='lg' calloutFooter={false}
          header={<h3><FormattedMessage id='footer.privacy' /></h3>}
          footer={<button className='btn btn-primary' onClick={this.hideModals}><FormattedMessage id='general.close' /></button>}>
          <ul className="pptou-section">
            <li><FormattedHTMLMessage id='footer.privacy.policyUS' /></li>
            <li><FormattedHTMLMessage id='footer.privacy.policyCA' /></li>
            <li><FormattedHTMLMessage id='footer.privacy.policyCAFR' /></li>
          </ul>
        </Modal>
      </div>
    </div>;
  },
});

export default injectIntl(LoginPage);
