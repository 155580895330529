import createRequest, {methodTypes} from '../helpers/request.js';

export function changePasswordReset(hash, password) {
  return createRequest('/api/Login/ChangePasswordReset', {
    body: {
      hash,
      password,
    },
    method: methodTypes.post,
  });
}
