import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import {findDOMNode} from 'react-dom';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import qwest from 'qwest';
import debounce from 'lodash.debounce';
import {injectIntl, intlShape, FormattedMessage} from 'react-intl';
import setTitle from 'helpers/setTitle';

import Card from '../../layout/Card/Card.jsx';

import styles from './ResetPassword.module.scss';
import Icon from '../../Icon/Icon.jsx';

const ResetPassword = createReactClass({
  displayName: 'ResetPassword',
  propTypes: {
    intl: intlShape.isRequired,
  },
  contextTypes: {
    setLocale: PropTypes.func.isRequried,
  },
  mixins: [PureRenderMixin],

  getInitialState() {
    return {
      email: '',
      message: '',
      success: false,
    };
  },

  componentWillMount() {
    this.submitForm = debounce(this._submitForm, 500, {leading: true, trailing: false});

    const params = new URLSearchParams(location.search);

    if (!params || !params.has('locale')) {
      return;
    }

    this.context.setLocale(params.get('locale'));
  },

  componentDidMount() {
    setTitle(this.props.intl.formatMessage, 'resetPassword.pageHeader');

    // Explicitly render captcha since by default only works on page when javascript first run
    if (window.grecaptcha && window.grecaptcha.render && this.refs.captcha) {
      const domNode = findDOMNode(this.refs.captcha);

      if (domNode && !domNode.innerHTML) {
        window.grecaptcha.render(domNode, {sitekey: '6LdTMSATAAAAAL2lE5UTWZlClUE2tGtOLycJBAjW'});
      }
    }
  },

  _submitForm(e) {
    e.preventDefault();
    this.submitReset();
  },

  submitReset() {
    const email = (this.state.email || '').trim();
    const {formatMessage} = this.props.intl;

    let recaptcha;

    const recaptchaResponse = document.querySelector('.g-recaptcha-response');

    if (recaptchaResponse) {
      recaptcha = recaptchaResponse.value;
    }

    qwest.post('/api/Login/ResetPassword', {
      email,
      recaptcha,
    })
      .then((xhr, response) => {
        if (window.grecaptcha && window.grecaptcha.reset) {
          window.grecaptcha.reset();
        }
        if (response.success) {
          this.setState({
            email: '',
            message: formatMessage({id: 'resetPassword.submitted'}),
          });
        } else {
          this.setState({
            email: '',
            message: response.message,
          });
        }
      });
  },

  updateEmail(e) {
    this.setState({
      email: e.target.value,
      message: '',
    });
  },

  render() {
    const {formatMessage} = this.props.intl;

    return <div className={styles.reset}>
      <Card header={formatMessage({id: 'resetPassword.title'})} className={styles.resetCard} minHeight={0}
        footer={<button className='btn btn-primary' type='submit' form='reset-form' onClick={this.submitForm}>
          <FormattedMessage id='resetPassword.submit' />
        </button>}>
        {this.state.message ? <div className='row'>
          <div className='col-xs-12'>
            <Icon name='error' verticalAlign='text-bottom' className='red' />
            <p className='b red inline-block'>{this.state.message}</p>
          </div>
        </div> : null}
        <div className='row'>
          <div className='col-xs-12'>
            <form onSubmit={this.submitForm} id='reset-form' ref='resetForm'>
              <div className='form-group'>
                <label><FormattedMessage id='resetPassword.fields.email' /></label>
                <input className='form-control' value={this.state.email} onChange={this.updateEmail} type='text'
                  placeholder={formatMessage({id: 'resetPassword.fields.email'})} autoFocus />
              </div>
              <div className='g-recaptcha' ref='captcha' data-sitekey='6LdTMSATAAAAAL2lE5UTWZlClUE2tGtOLycJBAjW' style={{marginBottom: 30}}/>
            </form>
          </div>
        </div>
      </Card>
    </div>;
  },
});

export default injectIntl(ResetPassword);