
var prevLocales = ['en-US'];

var events = require("C:/Users/Usuario/Projects/biolab-sso/SSO/Javascript/lib/events.js");

var addLocaleData = require('react-intl').addLocaleData;
var moment = require('moment');
var defaultCopy = require("C:/Users/Usuario/Projects/biolab-sso/SSO/Javascript/locale/copy.tsv?en-US");


if (window.isIntlPolyfilled) {
  require.ensure(['intl/locale-data/jsonp/en-US.js', 'react-intl/locale-data/en.js',
    "C:/Users/Usuario/Projects/biolab-sso/SSO/Javascript/locale/copy.tsv?en-US"
    ], function(require) {
    require('intl/locale-data/jsonp/en-US.js');
    var ln = require('react-intl/locale-data/en.js');
    addLocaleData(ln);
    
    
    
    moment.locale('en-us');
  });
} else {
  require.ensure(['react-intl/locale-data/en.js',
  "C:/Users/Usuario/Projects/biolab-sso/SSO/Javascript/locale/copy.tsv?en-US"
  ], function(require) {
    var ln = require('react-intl/locale-data/en.js');
    addLocaleData(ln);
    
    
    
    moment.locale('en-us');
  });
}

function changeLocale(locale, copy) {
  return events.publish('set-locale', {
    copy: copy,
    locale: locale,
  });
}


function setLocale(locale) {
  window.localStorage.setItem('locale', locale);
  if (prevLocales.indexOf(locale) !== -1) {
    changeLocale(locale);
    return;
  }

  switch (locale) {
    case 'fr-CA': {
                
        if (window.isIntlPolyfilled) {
          require.ensure(['intl/locale-data/jsonp/fr-CA.js', 'react-intl/locale-data/fr.js',
            "C:/Users/Usuario/Projects/biolab-sso/SSO/Javascript/locale/copy.tsv?fr-CA"
            , 'moment/locale/fr-ca'], function(require) {
            require('intl/locale-data/jsonp/fr-CA.js');
            var ln = require('react-intl/locale-data/fr.js');
            addLocaleData(ln);
            var copy = require("C:/Users/Usuario/Projects/biolab-sso/SSO/Javascript/locale/copy.tsv?fr-CA");
            changeLocale(locale, copy);
            require('moment/locale/fr-ca');
            moment.locale('fr-ca');
          });
        } else {
          require.ensure(['react-intl/locale-data/fr.js',
          "C:/Users/Usuario/Projects/biolab-sso/SSO/Javascript/locale/copy.tsv?fr-CA"
          , 'moment/locale/fr-ca'], function(require) {
            var ln = require('react-intl/locale-data/fr.js');
            addLocaleData(ln);
            var copy = require("C:/Users/Usuario/Projects/biolab-sso/SSO/Javascript/locale/copy.tsv?fr-CA");
            changeLocale(locale, copy);
            require('moment/locale/fr-ca');
            moment.locale('fr-ca');
          });
        }
      break;
    }
    default: {
              
        if (window.isIntlPolyfilled) {
          require.ensure(['intl/locale-data/jsonp/en-US.js', 'react-intl/locale-data/en.js',
            "C:/Users/Usuario/Projects/biolab-sso/SSO/Javascript/locale/copy.tsv?en-US"
            ], function(require) {
            require('intl/locale-data/jsonp/en-US.js');
            var ln = require('react-intl/locale-data/en.js');
            addLocaleData(ln);
            var copy = require("C:/Users/Usuario/Projects/biolab-sso/SSO/Javascript/locale/copy.tsv?en-US");
            changeLocale(locale, copy);
            
            moment.locale('en-us');
          });
        } else {
          require.ensure(['react-intl/locale-data/en.js',
          "C:/Users/Usuario/Projects/biolab-sso/SSO/Javascript/locale/copy.tsv?en-US"
          ], function(require) {
            var ln = require('react-intl/locale-data/en.js');
            addLocaleData(ln);
            var copy = require("C:/Users/Usuario/Projects/biolab-sso/SSO/Javascript/locale/copy.tsv?en-US");
            changeLocale(locale, copy);
            
            moment.locale('en-us');
          });
        }
      break;
    }
  }

  prevLocales.push(locale);
}

module.exports = {setLocale: setLocale, defaultCopy: defaultCopy, languages: [{"locale":"en-US","shortLocale":"en","regions":["US","CA"],"labels":{"en-US":"English","fr-CA":"Anglais"}},{"locale":"fr-CA","shortLocale":"fr","regions":["CA"],"labels":{"en-US":"French","fr-CA":"Français"}}]};
