import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import PureRenderMixin from 'react-addons-pure-render-mixin';

import NavBar from '../NavBar/NavBar.jsx';
import Footer from '../Footer/Footer.jsx';

import styles from './App.module.scss';


const App = createReactClass({
  displayName: 'App',
  mixins: [PureRenderMixin],
  propTypes: {
    children: PropTypes.any,
  },

  contextTypes: {
    setLocale: PropTypes.func.isRequired,
    currentLocale: PropTypes.string.isRequired,
    languages: PropTypes.array.isRequired,
  },

  getInitialState() {
    return {
      disclaimerModalVisible: false,
      privacyModalVisible: false,
    };
  },

  hideDisclaimerModal() {
    this.setState({
      disclaimerModalVisible: false,
    });
  },

  openDisclaimerModal() {
    this.setState({
      disclaimerModalVisible: true,
    });
  },

  hidePrivacyModal() {
    this.setState({
      privacyModalVisible: false,
    });
  },

  openPrivacyModal() {
    this.setState({
      privacyModalVisible: true,
    });
  },

  componentDidMount() {
    const locale = window.localStorage.getItem('locale');

    if (locale && this.context.currentLocale !== locale) {
      this.context.setLocale(locale);
    }
    if (!locale) {
      let navigatorLocale = window.navigator.language;

      if (navigatorLocale && typeof navigatorLocale === 'string') {
        navigatorLocale = navigatorLocale.toLowerCase().slice(0, 2);
      }

      const navFullLocale = this.context.languages.find(x => x.shortLocale === navigatorLocale);

      if (navFullLocale && navFullLocale.locale !== this.context.currentLocale) {
        this.context.setLocale(navFullLocale.locale);
      }
    }
  },

  render() {
    return <div className={styles.wrapper}>
      <NavBar />
      <div className={styles.mainContent}>
        <div className='container'>
          {this.props.children}
          <div className='clearfix' />
        </div>
      </div>
      <div className={styles.pushFooter} />
      <Footer
        disclaimerModalVisible={this.state.disclaimerModalVisible}
        hideDisclaimerModal={this.hideDisclaimerModal}
        openDisclaimerModal={this.openDisclaimerModal}
        locale={this.context.currentLocale}
        privacyModalVisible={this.state.privacyModalVisible}
        hidePrivacyModal={this.hidePrivacyModal}
        openPrivacyModal={this.openPrivacyModal}
        />
    </div>;
  },
});

export default App;
