import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import PureRenderMixin from 'react-addons-pure-render-mixin';

// Setup icon font here:
import './icons/biolab-icons.font';
import './Icon.scss';


const Icon = createReactClass({
  displayName: 'Icon',
  mixins: [PureRenderMixin],
  propTypes: {
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    style: PropTypes.object,
    verticalAlign: PropTypes.string,
  },

  getDefaultProps() {
    return {
      className: '',
      style: {},
    };
  },

  render() {
    const style = Object.assign({}, this.props.style);

    if (this.props.verticalAlign) {
      style.verticalAlign = this.props.verticalAlign;
    }

    const iconName = (this.props.name || '').includes('icon-') ? '' : `icon-${this.props.name}` ;

    return <i className={'icon biolabicons' + ' ' + iconName + ' ' + (this.props.className || '')} style={style}/>;
  },
});


export default Icon;
