import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import PureRenderMixin from 'react-addons-pure-render-mixin';

import '../../../../node_modules/spinkit/scss/spinners/7-three-bounce.scss';

const Loader = createReactClass({
  displayName: 'Loader',
  mixins: [PureRenderMixin],

  propTypes: {
    style: PropTypes.object,
  },

  render() {
    return <div className='sk-three-bounce' style={this.props.style}>
      <div className='sk-child sk-bounce1' />
      <div className='sk-child sk-bounce2' />
      <div className='sk-child sk-bounce3' />
    </div>;
  },
});

export default Loader;
