import React from 'react';
import createReactClass from 'create-react-class';

import LocaleSwitcher from '../LocaleSwitcher/LocaleSwitcher.jsx';

import styles from './NavBar.module.scss';
import logo from './logo.png';

function composeCSS(...args) {
  return args.join(' ');
}

const NavBar = createReactClass({
  displayName: 'NavBar',

  render() {
    return <div className={composeCSS('navbar', 'navbar-static-top', styles.navbar)}>
      <div className='container'>
        <div className='navbar-header' style={{float: 'left'}}>
          <a href={window.idmAddress}><img src={logo} height={60} style={{marginTop: 14}} title='Biolab User Admin Site' onClick={this.redirect} /></a>
        </div>
        <div className='pull-right'>
          <LocaleSwitcher />
        </div>
      </div>
    </div>;
  },
});

export default NavBar;
