import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';

import {wrapIfString} from '../../../helpers/strings.js';

import styles from './Card.module.scss';

const Card = createReactClass({
  displayName: 'Card',
  propTypes: {
    children: PropTypes.any.isRequired,
    className: PropTypes.string,
    footer: PropTypes.any,
    header: PropTypes.any,
    lightHeader: PropTypes.bool,
    style: PropTypes.object,
    noPadding: PropTypes.bool,
    noHeaderPadding: PropTypes.bool,
  },

  getDefaultProps() {
    return {
      lightHeader: false,
      noPadding: false,
      noHeaderPadding: false,
    };
  },

  render() {
    const header = wrapIfString(this.props.header, 'h3');

    let cardClass = styles.card;

    if (this.props.className) {
      cardClass += ' ' + this.props.className;
    }

    let bodyClass = styles.body;

    if (this.props.noPadding === true) {
      bodyClass += ' ' + styles.noPadding;
    }

    let headerClass = styles.header;

    if (this.props.noHeaderPadding === true) {
      headerClass += ' ' + styles.noPadding;
    }
    if (this.props.lightHeader === true) {
      headerClass += ' ' + styles.lightHeader;
    }

    return <div className={cardClass + ' card'} style={this.props.style}>
      {this.props.header ? <div className={headerClass}>{header}</div> : null}
      <div className={bodyClass}>{this.props.children}</div>
      {this.props.footer ? <div className={styles.footer}>{this.props.footer}</div> : null}
    </div>;
  },
});

module.exports = Card;
