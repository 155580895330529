import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import PureRenderMixin from 'react-addons-pure-render-mixin';

import Modal from '../Modal/Modal';

import {FormattedMessage, FormattedHTMLMessage, injectIntl, intlShape} from 'react-intl';

import styles from './Footer.module.scss';
import createRequest from 'helpers/request';

const Footer = createReactClass({
  displayName: 'Footer',
  mixins: [PureRenderMixin],
  propTypes: {
    disclaimerModalVisible: PropTypes.bool.isRequired,
    hideDisclaimerModal: PropTypes.func.isRequired,
    openDisclaimerModal: PropTypes.func.isRequired,
    privacyModalVisible: PropTypes.bool.isRequired,
    hidePrivacyModal: PropTypes.func.isRequired,
    openPrivacyModal: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    locale: PropTypes.string.isRequired,
  },

  hideModals() {
    this.props.hideDisclaimerModal();
    this.props.hidePrivacyModal();
  },

  render() {
    return <div className={styles.footer}>
      <div className={styles.spacer}>
        <div className={'container ' + styles.info}>
          <p className='pull-left'><FormattedMessage id='footer.copyright' values={{year: new Date().getFullYear()}} /></p>
          <p className='pull-right'>
            <span onClick={this.props.openDisclaimerModal} className={styles.legalLink}><FormattedMessage id='footer.terms' /></span>
            <span className='separator'> | </span>
            <span onClick={this.props.openPrivacyModal} className={styles.legalLink}><FormattedMessage id='footer.privacy' /></span>
          </p>
        </div>
      </div>
      <div>
        {/*Disclaimer Modal*/}
        <Modal visible={this.props.disclaimerModalVisible}
            close={this.props.hideDisclaimerModal}
            id='app-disclaimer-modal'
            size='lg'
            calloutFooter={false}
              header={<h3><FormattedMessage id='footer.terms' /></h3>}
              footer={<button className='btn btn-primary' onClick={this.hideModals}>
                <FormattedMessage id='general.close' /></button>}>
          <ul className="pptou-section">
            <li><FormattedHTMLMessage id='footer.terms.useUS'/></li>
            <li><FormattedHTMLMessage id='footer.terms.useCA'/></li>
            <li><FormattedHTMLMessage id='footer.terms.useCAFR'/></li>
          </ul>
        </Modal>
        {/*Privacy Modal*/}
        <Modal visible={this.props.privacyModalVisible} close={this.props.hidePrivacyModal}
             id='app-privacy-modal'
             size='lg'
             calloutFooter={false}
              header={<h3><FormattedMessage id='footer.privacy' /></h3>}
              footer={<button className='btn btn-primary' onClick={this.hideModals}>
                <FormattedMessage id='general.close' /></button>}>
          <ul className="pptou-section">
            <li><FormattedHTMLMessage id='footer.privacy.policyUS'/></li>
            <li><FormattedHTMLMessage id='footer.privacy.policyCA'/></li>
            <li><FormattedHTMLMessage id='footer.privacy.policyCAFR'/></li>
          </ul>
        </Modal>
      </div>
    </div>;
  },
});

export default injectIntl(Footer);
