import 'bootstrap/dist/css/bootstrap.min.css';
import './theme/main.module.scss';

import urlSearchParamsPolyfill from 'url-search-params';
import React from 'react';
import {render} from 'react-dom';
import {Router, Route, browserHistory, IndexRoute} from 'react-router';
import IntlProvider from './components/layout/IntlProvider/IntlProvider.jsx';

import App from './components/layout/App/App.jsx';
import LogoutPage from './components/pages/LogoutPage/LogoutPage.jsx';
import LoginPage from './components/pages/LoginPage/LoginPage.jsx';
import ResetPassword from './components/pages/ResetPassword/ResetPassword.jsx';
import ChangePassword from './components/pages/ChangePassword/ChangePassword.jsx';
import SavedPassword from './components/pages/SavedPassword/SavedPassword.jsx';
import RedirectError from './components/pages/RedirectError/RedirectError.jsx';

window.URLSearchParams = window.URLSearchParams || urlSearchParamsPolyfill;

render(
  <IntlProvider>
    <Router history={browserHistory}>
      <Route path='/' component={App}>
        <IndexRoute component={ResetPassword} />
        <Route path='reset-password' component={ResetPassword} />
        <Route path='change-password/:hash' component={ChangePassword} />
        <Route path='saved-password' component={SavedPassword} />
        <Route path='login/:id' component={LoginPage} />
        <Route path='logged-out' component={LogoutPage} />
        <Route path='session-error' component={RedirectError} />
      </Route>
    </Router>
  </IntlProvider>
  , document.getElementById('app-container'));
