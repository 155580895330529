import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import styles from './Link.module.scss';


const Link = createReactClass({
  propTypes: {
    label: PropTypes.string,
    action: PropTypes.any,
    icon: PropTypes.string,
    underline: PropTypes.bool,
    bold: PropTypes.bool,
  },
  render() {
    const {action, icon, label, underline, bold} = this.props;
    const iconElement = !!icon ? <i className={icon} /> : null;

    return (
      <a className={`${underline ? styles.underlinelink  : ''} ${bold ? styles.boldlink  : ''}  ${styles.customlink}`}
        onClick={action}>{iconElement}{label}</a>
    );
  },
});

export default Link;
