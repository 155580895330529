import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import PureRenderMixin from 'react-addons-pure-render-mixin';

import './PasswordInput.scss';

const PasswordInput = createReactClass({
  displayName: 'PasswordInput',
  mixins: [PureRenderMixin],
  propTypes: {
    autoFocus: PropTypes.bool,
    defaultValue: PropTypes.string,
    onChange: PropTypes.func,
    score: PropTypes.number,
    value: PropTypes.string,
  },
  render() {
    const scores = [];

    if (this.props.score || this.props.score === 0) {
      for (let i = 0; i < 6; ++i) {
        let style = 'bar';

        if (i <= this.props.score && this.props.score !== 0) {
          if (i < 2) {
            style += ' weak';
          } else if (i < 4) {
            style += ' medium';
          } else if (i < 6) {
            style += ' strong';
          }
        }
        scores.push(<div className={style} key={i} />);
      }
    }

    return <div className='password-input'>
      <input className={'form-control input'} type='password' onChange={this.props.onChange} ref='underlying'
        value={this.props.value} defaultValue={this.props.defaultValue} autoFocus={this.props.autoFocus !== undefined && this.props.autoFocus !== false} />
      <div className='scoreContainer'>
        {scores}
      </div>
    </div>;
  },
});

export default PasswordInput;
